import { AfterContentChecked, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { LocationsHttpService } from '../../services/locations-http.service';
import { AppConstants } from 'src/app/shared/constants/constants';
import { LOCATIONS_CONSTANTS } from '../../interfaces/locations-constants';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
import { CircuitsServicesService } from '../../services/circuits-services.service';

@Component({
  selector: 'app-circuit-details-form',
  templateUrl: './circuit-details-form.component.html',
  styleUrls: ['./circuit-details-form.component.scss']
})
export class CircuitDetailsFormComponent implements OnInit, OnDestroy, AfterContentChecked, OnChanges {

  form!: UntypedFormGroup;
  @Input() circuitData: any;
  subscribeCircuitFormData: any;
  subscribeMediaData: any;
  subscribeDeleteMediaData: any;
  mediaFiles: any;
  deleteMediaFiles: any;
  wordCount = 0;
  showToast = false;
  toastMessage: any;
  position: any;
  toastType: any;
  leftPosition: any;
  translationData: any;
  commonLabels = AppConstants.COMMON_LABELS;
  locationConstants = LOCATIONS_CONSTANTS;
  hasImage: boolean = false;
  @Input() imageUrls: any[] = [];
  urlRegex = this.locationConstants.URL_VALIDATION_REGEX_PATTERN;


  constructor(private formBuilder: UntypedFormBuilder,
    private locationsHttpService: LocationsHttpService,
    private router: Router,
    private toastService: ToastService,
    private _spinnerService: LoadingSpinnerService,
    private translationService: TranslationService,
    private _circuitService: CircuitsServicesService) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
    this.subscribeCircuitFormData = this.locationsHttpService.shareCircuitformData.subscribe(
      (data) => {
        if (data === 'save') {
          this.onSubmit();
        }
      }
    );
   
    let formValue = localStorage.getItem('form-data');
    if(formValue) {
      formValue = JSON.parse(formValue)
      this.getFormDetailsFromStorage(formValue);
    } else{
      this.circuitData.website = this.circuitData.web;
      this.getFormDetailsFromStorage(this.circuitData);
    }
  }

  ngAfterContentChecked() {
    this.textAreaAdjust();  
  }

  ngOnChanges(): void {
    this.hasImage = this.checkIfImageExists();
    if (!this.hasImage) {
      this.form.controls.copyright.reset();
    }
  }

  getFormDetailsFromStorage(data:any) {
    this.form = this.formBuilder.group({
      name: [data.name || '', [Validators.required]],
      guid: data.guid || '',
      description: data.description || '',
      website: data.website || '',
      wikipedia: data.wikipedia || '',
      address: data.address || '',
      type: data.type || 'TEMPORARY',
      copyright: this.formBuilder.group({
      attribution: data.media?.image[0]?.copyright?.attribution || null,
      license: data.media?.image[0]?.copyright?.license || null,
      license_url: [data.media?.image[0]?.copyright?.license_url || null, [Validators.pattern(this.urlRegex)]],
      subject_url: [data.media?.image[0]?.copyright?.subject_url || null, [Validators.pattern(this.urlRegex)]]
      })
    });
    this.wordCount = this.form.value.description.length;
    this.hasImage = this.checkIfImageExists();
  }

  checkIfImageExists() {
    return this.circuitData?.media?.image.length && this.imageUrls.length
  }

  getMedia() {
    this.subscribeMediaData =
      this.locationsHttpService.sharemediaData.subscribe((data) => {
        this.mediaFiles = data[0];
      });

    this.subscribeDeleteMediaData =
      this.locationsHttpService.deletemediaData.subscribe((data) => {
        this.deleteMediaFiles = data[0];
      });
  }


  initializeFormDetails() {
    this.form = this.formBuilder.group({
      name: this.circuitData.name?this.circuitData.name: '',
      guid: this.circuitData.guid?this.circuitData.guid: '',
      description: this.circuitData.description ? this.circuitData.description : '',
      website: this.circuitData.web?this.circuitData.web: '',
      wikipedia: this.circuitData.wikipedia?this.circuitData.wikipedia: '',
      address: this.circuitData.address?this.circuitData.address: '',
      type: this.circuitData.type?this.circuitData.type: 'TEMPORARY'
    });
    this.wordCount = this.form.value.description.length;
  }

  checkWordCount() {
    this.wordCount = this.form.value.description.length;
  }

  textAreaAdjust() {
    let textbox = document.getElementById("description")
    if(textbox) {
      textbox.style.overflow = "hidden";
      textbox.style.height = "1px";
      textbox.style.height = (textbox.scrollHeight)+"px";
    }  
    if(textbox && textbox.scrollHeight > 100 ) {
      textbox.style.overflow = "auto"
      textbox.classList.add("scroll-style");
    }  
  }

  public buildCreateEditCircuitRequest(isEditCircuit: boolean): any {
    const circuitRequest: { [key: string]: any } = this.getDirtyValues(this.form);
    
    if (isEditCircuit) {
      circuitRequest['guid'] = this.form.value.guid;
    }

    if (this.deleteMediaFiles) {
      circuitRequest['deleteMediaIds'] = [this.deleteMediaFiles];
    }

    if (this.form.value['copyright']) {
      circuitRequest['copyright'] = {
        ...this.form.value.copyright,
        complete: true
      };
    }

    if (this.isEmptyCopyright(circuitRequest['copyright'])) {
      delete circuitRequest['copyright'];
    }

    if (!isEditCircuit) {
      this.appendPolylineDetailsForCreate(circuitRequest);
      circuitRequest['type'] = this.form.controls.type.value;
    }

    return circuitRequest;
  }

  public getDirtyValues(form: UntypedFormGroup): { [key: string]: any } {
    const dirtyValues: { [key: string]: any } = {};
    Object.keys(form.controls).forEach(key => {
      if (form.controls[key].dirty) {
        dirtyValues[key] = form.controls[key].value;
      }
    });
    return dirtyValues;
  }

  public isEmptyCopyright(copyright: any): boolean {
    return !copyright?.attribution && !copyright?.license && 
           !copyright?.license_url && !copyright?.subject_url;
  }

  /**
   * @description On form submit, call the API to create or update the circuit
   */
  public onSubmit(): void {
    if (this.form.invalid) {
      const invalidFields = Object.keys(this.form.controls).filter(control => this.form.controls[control].invalid);
      const errorMessage = invalidFields.includes('name') 
      ? LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.CIRCUIT_NAME_REQ 
      : 'Please verify the input fields.';
      
      this.toastService.show(ToastType.Error, errorMessage, '1%', '40%');
      setTimeout(() => {
      this.toastService.hide();
      }, AppConstants.MIN_TIME_TOASTER);
      return;
    }
    this._spinnerService.show();
    this.getMedia();
    const circuitRequest = this.buildCreateEditCircuitRequest(!this.circuitData.isPrevPageLocation)
    if (!this.circuitData.isPrevPageLocation) {
      this.callUpdateCircuitApi(circuitRequest);
    } else {
      this.callCreateCircuitApi(circuitRequest)
    }
  }

  public appendPolylineDetailsForCreate(circuitRequest: any): any {
    if (this.circuitData?.coordinates.radius) {
        circuitRequest.radius = this.circuitData.coordinates.radius
        circuitRequest.coordinates = this.circuitData.coordinates.lat.toString() + "," + this.circuitData.coordinates.lng
      } else {
        circuitRequest.encodedPolyline = this.circuitData.coordinates
      }
    return circuitRequest;
  }

  public callCreateCircuitApi(circuitRequest: any): void {    
    this.locationsHttpService.createCircuit(circuitRequest, this.mediaFiles).subscribe({
      next: (response: any) => {
        this.handleCreateEditCircuitResponse(response, false)
      },
      error: (error) => {
        this.handleCreateEditCircuitError(error);
      },
    });
  }

  public callUpdateCircuitApi(circuitRequest: any): void {
    this.locationsHttpService
      .updateCircuitWithMedia(circuitRequest, this.mediaFiles)
      .subscribe(
        {
          next: (response) => {
            this.handleCreateEditCircuitResponse(response, true);
          },
          error: (error) => {
            this.handleCreateEditCircuitError(error)
          }
        }
      );
  }

  public handleCreateEditCircuitResponse(response: any, isEditingForm: boolean): void {
    const data = response.body
    if (data.message) {
      const successMsg = isEditingForm ? LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.CIRCUIT_UPDATE_SUCCESS_MSG: LOCATIONS_CONSTANTS.TOAST_SUCCESS_MESSAGES.CIRCUIT_CREATE_SUCCESS_MSG;
      this.toastService.show(ToastType.Success, successMsg, '1%', '40%');
      setTimeout(() => {
        // only for edit
        const guid: string = isEditingForm ? this.circuitData.guid: data.guid
        if (isEditingForm) { localStorage.removeItem('form-image') }        
        this.toastService.hide();
        this._spinnerService.hide();
        this.router.navigateByUrl(
          `/locations/circuit/${guid}`
        );
      }, AppConstants.MIN_TIME_TOASTER);
    }
  }

  public handleCreateEditCircuitError(error: any): void {
    this._spinnerService.hide();
    const errorMsg = error.error['error-message'] ? error.error['error-message'].split(':')[1] : AppConstants.COMMON_ERROR_MSGS.SOMETHING_WENT_WRONG_MSG;
    this.toastService.show(ToastType.Error, errorMsg, '1%', '40%');
    setTimeout(() => {
      this.toastService.hide();
    }, AppConstants.MIN_TIME_TOASTER);
  }


  ngOnDestroy() {
    localStorage.setItem('form-data', JSON.stringify(this.form.value));
    if(this.subscribeCircuitFormData){this.subscribeCircuitFormData.unsubscribe();}
    if(this.subscribeMediaData){this.subscribeMediaData.unsubscribe();}
    if(this.subscribeDeleteMediaData){this.subscribeDeleteMediaData.unsubscribe();}   
    this._circuitService.setCoordinateValue(null)
  }

}