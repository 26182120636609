export class LOCATIONS_CONSTANTS {

  static MAX_TIME: number = 5000;
  static MIN_TIME: number = 2000;
  static MAP_RADIUS: number = 3000;
  static MAX_IMAGE_SIZE: number = 15000000;
  static GEOJSON_MAX_COUNT: number = 5;
  static MAX_STATE_ZOOM_FOR_MAP_PINS: number = 18.5;
  static MAX_STREET_ZOOM_FOR_MAP_PINS: number = 14;
  static MIN_MAP_ZOOM_FOR_CURR_LOC: number = 8;
  static MIN_MAP_ZOOM_FOR_NO_LOC: number = 3.75;
  static MIN_ZOOM_OUT_START: number = 7.75;
  static MIN_ZOOM_OUT_DESTINATION: number = 3.75;
  static STREET_LEVEL_ZOOM: number = 15;
  static LANDING_PAGE_MAX_ZOOM_SAT_NOR: number = 19;
  static CIRCUIT_LEVEL: number = 13;
  static CIRCUIT_PUBLISHED_ZOOM: number = 12;
  static TRACK_LAYOUT_ZOOM: number = 13.5;
  static ALLOWED_EXTENTIONS_IMG_UPLOAD: string[] = ["jpg", "jpeg", "png"];
  static MAX_SEARCH_RESULTS_TO_STORE = 10;
  static DEVICE_TOP_LEFT_PADDING: number[] = [440, 88];
  static DEVICE_BOTTOM_RIGHT_PADDING: number[] = [64, 180];
  static INITIAL_PAGE_NUMBER_FOR_SEARCH: number = 0;
  static PAGE_SIZE_FOR_SEARCH: number = 15;
  static ET_PIN_ICON_SIZE_COMMON: any[] = [32, 48];
  static ET_PIN_ICON_POSITION_COMMON: any[] = [16, 40]
  // To validate a URL that starts with http://, https://, or ftp:// and also support data URLs like (data:image/jpeg;base64,...):
  static URL_VALIDATION_REGEX_PATTERN = /^(https?|ftp):\/\/[a-z0-9-._~%]+(?:\.[a-z0-9-._~%]+)*(?::\d{2,5})?(?:\/[^\s]*)?$|^data:image\/[a-zA-Z]+;base64,[^\s]+$/i

  static TOAST_SUCCESS_MESSAGES = {
    FILE_UPLOADED_SUCCESS: 'BP_CM_FILE_UPLOAD_SUCCESS',
    TOUR_SENT_TO_CAR_SUCCESS: 'BP_LM_TOUR_SENT_SUCCESS',
    URL_COPIED_SUCCESS_MSG: 'BP_LM_URL_COPIED_SUCCESS',
    CIRCUIT_CREATE_SUCCESS_MSG: 'BP_LM_CIRCUIT_CREATED_SUCCESS',
    CIRCUIT_DELETE_SUCCESS_MSG: 'BP_LM_CIRCUIT_DELETED_SUCCESS',
    CIRCUIT_UPDATE_SUCCESS_MSG: 'BP_LM_CIRCUIT_UPDATED_SUCCESS',
    EMOTION_TOUR_CREATE_SUCCESS_MSG: 'BP_LM_TOUR_CREATED_SUCCESS',
    EMOTION_TOUR_UPDATE_SUCCESS_MSG: 'BP_LM_TOUR_UPDATED_SUCCESS',
    EMOTION_TOUR_DELETE_SUCCESS_MSG: 'BP_LM_TOUR_DELETED_SUCCESS',
    OFFROAD_CREATE_SUCCESS_MSG: 'BP_LM_OFFROAD_CREATED_SUCCESS',
    OFFROAD_UPDATE_SUCCESS_MSG: 'BP_LM_OFFROAD_UPDATED_SUCCESS',
    OFFROAD_DELETE_SUCCESS_MSG: 'BP_LM_OFFROAD_DELETED_SUCCESS',
    INSIDER_SPOT_CREATE_SUCCESS_MSG: 'BP_LM_INSIDER_SPOT_CREATED_SUCCESS',
    INSIDER_SPOT_UPDATE_SUCCESS_MSG: 'BP_LM_INSIDER_SPOT_UPDATED_SUCCESS',
    INSIDER_SPOT_DELETE_SUCCESS_MSG: 'BP_LM_INSIDER_SPOT_DELETED_SUCCESS',
    TRACK_CREATE_SUCCESS_MSG: 'BP_LM_TRACK_CREATED_SUCCESS',
    TRACK_UPDATE_SUCCESS_MSG: 'BP_LM_TRACK_UPDATED_SUCCESS',
    TRACK_DELETE_SUCCESS_MSG: 'BP_LM_TRACK_DELETED_SUCCESS',
    IMAGE_COPYRIGHT_SAVED_SUCCESS_MSG: 'BP_LM_IMAGE_COPYRIGHT_SAVED_SUCCESS_MSG',
  }

  static TOAST_ERROR_MSGS = {
    PAGE_NOT_FOUND_MSG: 'Sorry, page not found!',
    ALLOWED_MEDIA_EXTENSION_MSG:'BP_CM_INVALID_IMAGE_FORMAT_MSG',
    MAX_ALLOWED_MEDIA_MSG: 'BP_CM_IMG_MAX_SIZE_MSG',
    TRACK_EXISTS_MSG: 'BP_LM_TRACK_EXISTS_MSG',
    GEOFENCE_VALIDATION_ERROR: 'BP_LM_GEOFENCE_VALIDATION_ERROR',
    CIRCUIT_GUID_INVALID: 'Invalid circuit guid',
    INVALID_COORDINATES: 'Invalid coordinates',
    INVALID_GEOFENCE: 'Invalid geo-fence',
    INVALID_CIRCUIT_TYPE: 'Invalid circuit type',
    GEO_FENCE_OVERLAP_ERR: 'BP_LM_GEO_FENCE_OVERLAP_ERR',
    INVALID_VALUE_ENETERED: 'BP_LM_INVALID_VALUE_ENETERED',
    TOUR_DOES_NOT_EXIST: 'BP_LM_TOUR_DOES_NOT_EXIST',
    EMPTY_GEOFENCE_ERROR: 'BP_LM_EMPTY_GEOFENCE_ERROR',
    WRONG_FILE_UPLOADED_ERR: 'BP_LM_WRONG_FILE_UPLOAD_ERR',
    CIRCUIT_WRONG_FILE_UPLOADED_ERR: 'BP_LM_CIRCUIT_WRONG_FILE_UPLOADED_ERR',
    CIRCUIT_CREATE_ERR: "BP_LM_CIRCUIT_CREATE_ERR",
    ADDRESS_NOT_FOUND: 'BP_LM_ADDRESS_NOT_FOUND',
    CIRCUIT_NAME_REQ: 'BP_LM_CIRCUIT_NAME_REQ',
    CIRCUIT_COORDINATES_REQ: 'BP_LM_CIRCUIT_COORDINATES_REQ',
    CIRCUIT_TYPE_REQ: 'BP_LM_CIRCUIT_TYPE_REQ',
    CIRCUIT_RADIUS_REQ: 'BP_LM_CIRCUIT_RADIUS_REQ',
    MAX_ONE_MEDIA_FILE: 'BP_LM_MAX_ONE_MEDIA_FILE',
    ET_INVALID_GUID: 'BP_LM_ET_INVALID_GUID',
    ET_INVLAID_TRACK_FORMAT: 'BP_LM_ET_INVLAID_TRACK_FORMAT',
    ET_GUID_REQ: 'BP_LM_ET_GUID_REQ',
    ET_AMG_ANNOTATION_EXISTS: 'BP_LM_ET_AMG_ANNOTATION_EXISTS',
    ET_TOUR_ID_REQ: 'BP_LM_ET_TOUR_ID_REQ',
    ET_NAME_REQ: 'BP_LM_TOUR_NAME_EMPTY' ,
    CIRCUIT_GUID_REQ: 'BP_LM_CIRCUIT_GUID_REQ',  
    MISSING_TRACK_DETAILS: 'BP_LM_MISSING_TRACK_DETAILS',
    INVAID_FILE_TYPE: 'BP_LM_INVAID_FILE_TYPE',
    INVALID_TRACK_GUID_FORMAT: 'BP_LM_INVALID_TRACK_GUID_FORMAT',
    VIN_OR_FIN_REQUIRED: 'BP_LM_VIN_OR_FIN_REQUIRED',
    ANNOTATION_ID_REQUIRED: 'BP_LM_ANNOTATION_ID_REQUIRED',
    PROFILE_ID_REQUIRED: 'BP_LM_PROFILE_ID_REQUIRED',
    INVALID_ANNOTATION_NAME: 'BP_LM_INVALID_ANNOTATION_NAME',
    INVALID_ANNOTATION_LANDMARK: 'BP_LM_INVALID_ANNOTATION_LANDMARK',
    INVALID_ANNOTATION_DESC : "BP_LM_INVALID_ANNOTATION_DESCRIPTION",
    INVALID_ANNOTATION_PROPERTIES: "BP_LM_INVALID_ANNOTATION_PROPERTIES",
    FILE_UPLOAD_ERR: 'BP_LM_FILE_UPLOAD_ERR',
  }


  static ALERT_POPUP_TITLE = {
    CANCEL_EMOTION_TOUR_TITLE: 'BP_LM_CANCEL_EMOTION_TOUR_TITLE',
    DLT_INSDR_SPT_TITLE: 'BP_LM_DLT_INSDR_SPT_TITLE',
    CANCEL_EDITING_CARD_DTLS: 'BP_LM_CANCEL_EDITING_CARD_DTLS',
    CONFIRM_CANCEL: 'BP_LM_CONFIRM_CANCEL',
    DELETE_TRACK_TITLE: 'BP_LM_DELETE_TRACK_TITLE',
    PUBLISH_NOW: 'BP_LM_PUBLISH_NOW',
    ALERT: 'BP_LM_ALERT',
    CONFIRM_DELETE: 'BP_LM_CONFIRM_DELETE',
    DELETE_ET_TITLE: 'BP_LM_DELETE_ET_TITLE',
    DELETE_OFFROAD_TITLE: 'BP_LM_DELETE_OFFROAD_TITLE',
    CANCEL_OFFROAD_TRACK_TITLE: 'BP_LM_CANCEL_OFFROAD_TRACK_TITLE',
    IMAGE_COPYRIGHT_DETAILS: 'BP_LM_IMAGE_COPYRIGHT_DETAILS',
    FLORA_FAUNA_TITLE: 'BP_LM_FLORA_FAUNA_TITLE'
  }

  static CONFIRMATION_POPUP_MSGS = {
    EDIT_CIRCUIT_UNSAVED_CHANGES: 'BP_LM_CONFIRM_ALERT_MESSAGE',
    DELETE_EMOTION_TOUR_MSG: 'BP_LM_DELETE_EMOTION_TOUR_MSG',
    DISCARD_EMOTION_TOUR_MSG: 'BP_LM_DISCARD_EMOTION_TOUR_MSG',
    DISCARD_INSIDER_SPOT_MSG: 'BP_LM_DISCARD_INSIDER_SPOT_MSG',
    DISCARD_EDIT_INSIDER_SPOT_MSG: 'BP_LM_DISCARD_EDIT_INSIDER_SPOT_MSG',
    DELETE_INSIDER_SPT_MSG: 'BP_LM_DELETE_INSIDER_SPT_MSG',
    DELETE_TRACK_LAYOUT: 'BP_LM_DELETE_TRACK_LAYOUT',
    DELETE_TRACK_MSG: 'BP_LM_DELETE_TRACK_MSG',
    UNSAVED_CHANGES_WARNING: 'BP_LM_UNSAVED_CHANGES_WARNING',
    DELETE_OFFROAD_TRACK_MSG: 'BP_LM_DELETE_OFFROAD_TRACK_MSG',
    DISCARD_OFFROAD_TRACK_MSG: 'BP_LM_DISCARD_OFFROAD_TRACK_MSG',
    SEND_TRACK_TO_FLEET_MSG: 'BP_LM_SEND_TRACK_TO_FLEET_MSG',
  }

  static BUTTON_NAMES = {
    SAVE_AND_UPDATE: 'BP_LM_SAVE_UPDATE_BTN_LBL',
    SAVE_AND_PUBLISH: 'BP_LM_SAVE_PUBLISH_BTN_LBL',
    SET_AS_DESTINATION: 'BP_LM_ADD_DESTINATION_LBL',
    ADD_DETAILS: 'BP_LM_ADD_DETAILS_BTN_LBL',
    SET_AS_START: 'BP_LM_ADD_START_BTN_LBL',
    BACK_TO_LOCATIONS: 'BP_LM_BACK_TO_LOCATIONS_BTN_LBL',
    SAVE_INSIDER_SPOT:'BP_LM_SAVE_INSIDER_POINT_BTN_LBL',
    UPDATE_INSIDER_SPOT:'BP_LM_UPDATE_DETAILS_BTN_LBL',
    GENERATE_TOUR: 'BP_LM_GENERATE_TOUR_BTN_LBL',
    PUBLISH_TOUR: 'BP_LM_PUBLISH_BTN_LBL',
    NEW: 'BP_LM_NEW_LBL',
    CIRCUITS: 'BP_LM_CIRCUITS_LBL',
    EMOTION_TOUR: 'BP_LM_EMOTION_TOUR_LBL',
    OFFROAD: 'BP_LM_OFFROAD_LBL',
    ROUND_TRIP: 'BP_LM_CREATE_RT_BTN_LBL',
    NEW_LAYOUT: 'BP_LM_NEW_LAYOUT_BTN_LBL',
    EDIT_GEOFENCE: 'BP_LM_EDIT_GEOFENCE_BTN_LBL',
    SAVE_DRAFT: 'BP_LM_SAVE_DRAFT_BTN_LBL',
    NEXT : 'BP_LM_NEXT_BTN_LBL',
    BACK : 'BP_LM_BACK_BTN_LBL',
    CREATE : 'BP_LM_CREATE_BTN_LBL',
    VIEW_SUMMARY: 'BP_LM_VIEW_SUMMARY_BTN_LBL',
  }

  static LEFT_PANEL_TITLES = {
    ADD_START_TITLE: 'BP_LM_ADD_STRT_MSG_NEW',
    ADD_DESTINATION_TITLE: 'BP_LM_ADD_DSTN_NEW',
    EDIT_INSIDER_SPOT_TITLE: 'BP_LM_EDT_INSDR_SPT_NEW',
    ADD_INSIDER_SPOT_TITLE: 'BP_LM_ADD_INSDR_SPT_NEW',
    TOUR_DETAILS_TITLE :'BP_LM_TOUR_DETAILS',
    UPDATE_START_TITLE: 'BP_LM_UPDT_STRT',
    UPDATE_DESTINATION_TITLE: 'BP_LM_UPDT_DSTN',
  } 

  static CLUSTER_TAGS = {
    TRACK_PACE: 'BP_LM_TRACK_PACE_LBL',
    EMOTION_TOUR: 'BP_LM_EMOTION_TOUR_LBL',
    OFFROAD_ADVENTURE: 'BP_LM_OFFROAD_ADV_LBL',
  }

  static HELPER_INPUT_LABELS = {
    CIRCUIT_COORDINATES :'BP_LM_CIRCUIT_COORDINATES_LBL',
    CIRCUIT_RADIUS: 'BP_LM_CIRCUIT_RADIUS_LBL',
    CIRCUIT_GEO_FENCE: 'BP_LM_CIRCUIT_GEO_FENCE_AREA',
    NO_OF_LAYOUTS: 'BP_LM_NO_OF_LAYOUTS',
    DRAFT: 'BP_LM_DRAFT_LBL',
    NEW: this.BUTTON_NAMES.NEW,
    CHANGE_IMG: 'BP_LM_CHANGE_IMG',
    ADDRESS: 'BP_LM_ADDRESS',
    SET_DISPLAY_NAME : 'BP_LM_SET_DISPLAY_NAME',
    LAYOUT_DETAILS: 'BP_LM_LAYOUT_DETAILS',
    CIRCUIT_DETAILS: 'BP_LM_CIRCUIT_DETAILS',
    IMAGE_COPYRIGHT_INFO: 'BP_LM_IMAGE_COPYRIGHT_INFO',
    ENTER_CIRCUIT_NAME: 'BP_LM_ENTER_CIRCUIT_NAME',
    SET_CIRCUIT_NAME: 'BP_LM_SET_CIRCUIT_NAME',
    WEBSITE:'BP_LM_WEBSITE',
    WIKIPEDIA: 'BP_LM_WIKIPEDIA',
    RADIUS: 'BP_LM_RADIUS_LBL',
    POLYLINE: 'BP_LM_POLYLINE_LBL',
    CLEAR: 'BP_LM_CLEAR_LBL',
    IMAGE_ATTRIBUTION: 'BP_LM_IMAGE_ATTRIBUTION',
    IMAGE_LICENSE: 'BP_LM_IMAGE_LICENSE',
    LICENCE_URL: 'BP_LM_LICENCE_URL',
    IMAGE_URL: 'BP_LM_IMAGE_URL',
  }

  static HELPER_MSGS = { 
    SEARCH_ALL_LOCATIONS_MSG: 'SEARCH_ALL_LOCATIONS_MSG',
    ADD_START_INFO_MSG: 'BP_LM_ADD_STRT_LCN_MSG_NEW',
    ADD_DESTINATION_INFO_MSG: 'BP_LM_ADD_DEST_LCN_MSG_NEW',
    ALL_INSIDER_SPOTS_MSG: 'BP_LM_ADD_INSDR_NEW',
    CREATE_ROUND_TRIP_MSG: 'BP_LM_ROUND_TRIP_NOTE',
    UPLOAD_GPX_CIRCUIT_MSG: 'BP_LM_UPLOAD_GPX_FILE_MSG',
    NO_LAYOUTS_AVAILABLE_MSG: 'BP_LM_NO_LAYOUTS_AVAILABLE_MSG',
    RESET_GEOFENCE_MSG:'BP_LM_CIRCUIT_RESET_MSG',
    BROWSE_LOCATIONS_MSG: 'BP_LM_BROWSE_LOCATIONS_MSG',
    SET_CENTER_CIRCLULAR: 'BP_LM_SET_CENTER_CIRCLULAR',
    DRAG_AND_HOLD_GEOFENCE: 'BP_LM_DRAG_GEOFENCE_MSG',
    ENTER_GEOFENCE_RADIUS: 'BP_LM_GEO_FENCE_RADIUS_MSG',
    START_CIRCUIT_CREATION: 'BP_LM_START_CIRCUIT_CREATION',
    ZOOM_GEO_FENCE_MSG: 'BP_LM_ZOOM_GEO_FENCE_MSG',
    NEXT_STEPS_MSG: 'BP_LM_NEXT_STEPS_MSG',
    VALIDATED_MSG: 'BP_LM_VALIDATED_MSG',
    VALIDATING: 'BP_LM_VALIDATING',
    VALIDATION_IN_PROGRESS: 'BP_LM_VALIDATION_IN_PROGRESS',
    UPLOADING_IN_PROGRESS: 'BP_CM_UPLOADING_FILE_LBL',
  }  
}